import * as requestFromServer from '../../../components/_redux/notarization_redux/notarizationCrud'
import { socketSlice, callTypes } from './socketSlice'
import { io } from 'socket.io-client'
const { actions } = socketSlice

const ENDPOINT = process.env.REACT_APP_MIDDLEWARE_URL

export const connect = (auth, location) => (dispatch) => {
    let socketconn

    let notaryID = auth.user.id
    socketconn = io(ENDPOINT, {
        transports: ['websocket'],
        path: '/api/socket',
    })

    socketconn.on('connect', () => {
        dispatch(actions.socketSet(socketconn))
        socketconn.emit('join', { notaryID }, () => {})
    })

    if ( auth.user.Role  && 'notary' == auth.user.Role.code) {
        // Agreement Completed
        socketconn.on('agreementCompleted', (data) => {
            dispatch(actions.agreementCompleted(data))
        })

        socketconn.on('scheduledNotaryResponse', (data) => {
            dispatch(actions.scheduledNotaryCompleted(data))
        })

        socketconn.on('scheduleNotarizationNotification', (data) => {
            dispatch(actions.scheduleNotarizationNotification(data))
        })

        socketconn.on('kbaVerificationRejected', (data) => {
            dispatch(actions.kbaVerificationRejectedNotary(data))
        })

        socketconn.on('kbaLink', (data) => {})

        socketconn.on('idologyLink', (data) => {
            dispatch(actions.callidologyLink(data))
        })

        socketconn.on('primaryNotaryChanged', (data) => {
            dispatch(actions.primaryNotaryChanged(data))
        })

        // Agreement Signed
        socketconn.on('agreementSigned', (data) => {
            dispatch(actions.agreementSigned(data))
        })

        // Notary Notification Completed
        socketconn.on('notaryNotification', (data) => {
            dispatch(actions.notaryNotification(data))
        })

        socketconn.on('witnessNotification', (data) => {
            dispatch(actions.witnessNotification(data))
        })

        socketconn.on('witnessJobNotification', (data) => {
            dispatch(actions.witnessJobNotification(data))
        })

        socketconn.on('jobPosted', (data) => {
            dispatch(actions.notarizationJobNotification(data))
        })

        socketconn.on('WitnessReadyNotification', (data) => {
            dispatch(actions.WitnessReadyNotification(data))
        })

        socketconn.on('adobeCredentialsUpdated', (data) => {
            dispatch(actions.adobeCredentialsUpdated(data))
        })
    } else {
        socketconn.on('scheduleNotarizationNotification', (data) => {
            dispatch(actions.scheduleNotarizationNotification(data))
        })

        socketconn.on('witnessNotification', (data) => {
            //	if("/signwitness/" != location.pathname)
            dispatch(actions.witnessNotification(data))
        })
        socketconn.on('scheduledNotaryResponse', (data) => {
            dispatch(actions.scheduledNotaryCompleted(data))
        })
        socketconn.on('notarizationRejected', (data) => {
            dispatch(actions.notarizationRejected(data))
        })

        socketconn.on('kbaLink', (data) => {})

        socketconn.on('notarizeItNotification', (data) => {
            dispatch(actions.notarizeItNotification(data))
        })

        socketconn.on('idologyLink', (data) => {
            dispatch(actions.callidologyLink(data))
        })

        socketconn.on('kbaVerificationDone', (data) => {
            if ('/my-signer-question' != location.pathname)
                dispatch(actions.kbaVerificationCompleted(data))
        })

        socketconn.on('kbaVerificationRejected', (data) => {
            if ('/my-signer-question' != location.pathname)
                dispatch(actions.kbaVerificationRejected(data))
        })
    }

    socketconn.on('idScanLink', (data) => {
        // try creating a new state and check
        dispatch(actions.idScanLink(data))
    })

    socketconn.on('biometricLink', (data) => {
        dispatch(actions.biometricLink(data))
    })

    socketconn.on('idProofing', (data) => {
        dispatch(actions.idProofing(data))
    })
    socketconn.on('kbaSignerStatus', (data) => {
        dispatch(actions.kbaSignerStatus(data))
    })
    socketconn.on('idScanSignerStatus', (data) => {
        dispatch(actions.idScanSignerStatus(data))
    })
    socketconn.on('biometricSignerStatus', (data) => {
        dispatch(actions.biometricSignerStatus(data))
    })
    socketconn.on('signerPageAttemptsExceeded', (data) => {
        dispatch(actions.signerPageAttemptsExceeded(data))
    })
    socketconn.on('signerPageAttempts', (data) => {
        dispatch(actions.signerPageAttempts(data))
    })

    socketconn.on('kbaVerification', (data) => {
        dispatch(actions.kbaVerificationSingleCompleted(data))
    })

    socketconn.on('statusChangedNotification', (data) => {
        dispatch(actions.statusChangedNotification(data))
    })

    socketconn.on('statusChangedNotificationUser', (data) => {
        dispatch(actions.statusChangedNotificationUser(data))
    })

    socketconn.on('newJobPostedNotification', (data) => {
        dispatch(actions.newJobPostedNotification(data))
    })

    socketconn.on('disconnect', () => {
        //socketconn.connect();
    })
}

export const getandcheckNotifications = (auth) => (dispatch) => {
    //	dispatch(actions.getandcheckNotifications());
    if ('notary' == auth.user.Role.code) {
        const headers = {
            Authorization: `Bearer ${auth.authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

        return requestFromServer
            .getNotificationCurrent(headers)
            .then((response) => {
                dispatch(actions.getandcheckNotifications(response))
            })
            .catch((error) => {
                if (
                    error.response.data.message ==
                    'Your profile is underreview please contact admin.'
                ) {
                    window.location.href = '#/logout'
                }
            })
    }
}

export const manualNotaryNotification = (data) => (dispatch) => {
    dispatch(actions.notaryNotification(data))
}

export const manualScheduledNotification = (data) => (dispatch) => {
    dispatch(actions.scheduleNotarizationNotification(data))
}

export const manualWitnessNotification = (data) => (dispatch) => {
    dispatch(actions.witnessNotification(data))
}

export const clearscheduleNotification = () => (dispatch) => {
    dispatch(actions.clearscheduleNotification())
}

export const emptynotarizationRejection = () => (dispatch) => {
    dispatch(actions.emptynotarizationRejection())
}

export const emptyNotarization = () => (dispatch) => {
    dispatch(actions.emptyNotarization())
}

export const completeNotarization = (journalID, documentCount) => (
    dispatch
) => {
    let data = { journalId: journalID, documentCount: documentCount }
    dispatch(actions.completeNotarization(data))
}
